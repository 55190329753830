import * as React from 'react'
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Container, ContainerPage, tablet, mobile } from '../components/container';
import * as colours from '../styling/colours';
import * as fonts from '../styling/fonts';
import { allGames } from '../games/Games';
import { GameSummary } from '../components/game';
import Layout from '../components/layouts';

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta
        name="description"
        content="A wide range of drinking games for any number of players - good for everything from University socials to student house parties and pre-drinks sessions."
      />
    </Helmet>
    <HeroContainer colour={colours.black}>
      <HeroContent>
        All the best drinking games for predrinks.
      </HeroContent>
    </HeroContainer>
    <Container colour="white">
      <Section>
        <h3><SectionImage src={require('../images/icons/card-games-black.svg')} alt="Card games." /> Card Games</h3>
        {allGames.filter(g => g.category === 'Card').map(g => <GameSummary gameDetails={g} />)}
      </Section>
    </Container>
    <Container colour="#e1e1e1">
      <Section>
        <h3><SectionImage src={require('../images/icons/word-games-black.svg')} alt="Word games." /> Word Games</h3>
        {allGames.filter(g => g.category === 'Word').map(g => <GameSummary gameDetails={g} />)}
      </Section>
    </Container>
    <Container colour="#d1d1d1">
      <Section>
        <h3><SectionImage src={require('../images/icons/other-games-black.svg')} alt="Other games." /> Other Games</h3>
        {allGames.filter(g => g.category === 'Other').map(g => <GameSummary gameDetails={g} />)}
      </Section>
    </Container>
  </Layout>
)

const HeroContainer = styled(Container)`
  &:after {
    content: '';
    border-top: solid 20px #222;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
  }
`;

const HeroContent = styled(ContainerPage)`
  padding-top: 25px;
  padding-bottom: 30px;
  color: ${colours.lightText};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  line-height: 25px;
`;

const HeroLeft = styled.div`
  max-width: 600px;

  h1 {
    margin-bottom: 18px;
    font-size: 3em;

    @media (max-width: ${tablet}px) {
      font-size: 1.8em;
    }

    @media (max-width: ${mobile}px) {
      font-size: 1.8em;
    }
  }

  h2 {
    margin-bottom: 35px;
    font-size: 1.8em;

    @media (max-width: ${tablet}px) {
      font-size: 1.3em;
    }

    @media (max-width: ${mobile}px) {
      font-size: 1.2em;
    }
  }
`;

const HeroRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobile}px) {
    display: none;
  }

  img {
    margin-top: 30px;
    height: 250px;
  }
`;

const Section = styled(ContainerPage)`
  padding-top: 40px;
  padding-bottom: 30px;

  h3 {
    font-size: 2em;
    font-family: ${fonts.headerFont};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;
    opacity: 0.75;
  }
`;

const SectionImage = styled.img`
  height: 60px;
  margin-right: 20px;
`;

export default IndexPage
